<template>
	<div class="page flex-col">
		<div class="box_1 flex-col" :class="startX<=600?'wid600':''">
			<div class="" v-if="startX<=600">
				<head-top :type="2" :startX="startX"></head-top>
				<div class="image1" @click.stop="toIndex(dataMain.banner.picture[0])">
					<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
						style="width: 100%;height:100%">
					<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
				</div>
				<!-- <el-carousel height="100%" class="image1">
					<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id"
						@click.stop="toIndex(item)">
						<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel> -->
				<!-- <img class="image1"
					src="./assets/img/ps2l2vydiv5fdyma43ac37he1p5mq2ig9a1193bb8-e3fc-442c-863f-5d255654d8d3.png" alt=""> -->
			</div>
			<div class="section_1 flex-col" v-else>
				<head-top :type="2" :startX="startX"></head-top>
				<div class="section1" @click.stop="toIndex(dataMain.banner.picture[0])">
					<img v-if="dataMain.banner.audio_type==0" :src="dataMain.banner.picture[0].abs_url" alt=""
						style="width: 100%;height:100%">
					<video v-else style="width: 100%;height:100%" :src="dataMain.banner.picture[0].abs_url"></video>
				</div>
				<!-- <el-carousel height="100%" class="section1">
					<el-carousel-item v-for="item in dataMain.banner.picture" :key="item.id"
						@click.stop="toIndex(item)">
						<img v-if="dataMain.banner.audio_type==0" :src="item.abs_url" alt=""
							style="width: 100%;height:100%">
						<video v-else style="width: 100%;height:100%" :src="item.abs_url"></video>
					</el-carousel-item>
				</el-carousel> -->
			</div>
			<div class="section_3 flex-col">

				<div class="box_2 flex-row justify-between">

					<div class="flex-col justify-between" v-if="startX>600"
						style="position: relative;max-height: 680px;height: 40vw;min-height: 400px;width: 48%;">
						<img class="image_3" referrerpolicy="no-referrer" :src="dataMain.about.cover_back" />
						<img class="image_50" referrerpolicy="no-referrer" :src="dataMain.about.cover_front" />
					</div>

					<div class="section_4 flex-col" id="about">
						<div class="text-wrapper_1">
							<span class="text_10">A</span>
							<span class="text_11">bout</span>
							<span class="text_12">U</span>
							<span class="text_13">s</span>
						</div>
						<span class="text_14">公司简介</span>
						<span class="paragraph_1" v-if="startX>600">
							{{dataMain.about.content}}
						</span>
					</div>
					<div class="paragraph_1" v-if="startX<=600">
						<img class="image_3" referrerpolicy="no-referrer" :src="dataMain.about.cover_front" />
						<img class="image50" :src="dataMain.about.cover_back" />
						<span style="width: 100%;">{{dataMain.about.content}}</span>
					</div>
				</div>
				<!--  -->
				<div class="text-wrapper_2 mainApp16" :style="isShowAnimation?'opacity: 1;':startX>600?'opacity: 0;':''">
					<span class="text_15">C</span>
					<span class="text_16">orporate</span>
					<span class="text_17">c</span>
					<span class="text_18">ulture</span>
				</div>
				<!--  -->
				<span class="text_19 mainApp16" :style="isShowAnimation?'opacity: 1;':startX>600?'opacity: 0;':''">企业文化</span>
				<!-- -->
				<div class=" flex-col" id="culture" :style="isShowAnimation?'opacity: 1;width:100%;':''"
					v-for="(item,index) in dataMain.culture" :key="(index+1)%2==1">
					<!--  -->
					<div class="box_3 flex-row" :style="!isShowAnimation?'justify-content: space-between;':''"
						v-if="(index+1)%2==1">
						<img class="image_4" referrerpolicy="no-referrer" :src="item.cover" />
						<div class="box_4 flex-col">
							<div class="image_6"></div>
							<span class="text_20">{{item.title}}</span>
							<span class="text_22">{{item.prop}}</span>
						</div>
					</div>
					<div class="box_5 flex-row justify-between" v-if="(index+1)%2==0">
						<div class="box_6 flex-col">
							<div class="image_6"></div>
							<div class="text-group_1 flex-col justify-between">
								<span class="text_23">{{item.title}}</span>
							</div>
							<span class="paragraph_2">
								{{item.prop}}
							</span>
						</div>
						<img class="image_7" referrerpolicy="no-referrer" :src="item.cover" />
					</div>
				</div>
				<div class="box_7" id="action">
					<div class="flex-col box7">
						<div class="image-wrapper_2 flex-row">
							<img class="image_8" referrerpolicy="no-referrer"
								:style="isShowAnimation1?'position: relative;top:0;opacity: 1':''"
								src="./assets/img/psh6b71zly1p7pwxw9sflzai0hbafs655h46fb2fb8-ecee-480f-afd7-ee0fc5db0d7a.png" />
						</div>
						<div class="text-wrapper_3 flex-row">
							<span class="text_25"
								:style="isShowAnimation1?'position: relative;top:0;opacity: 1':''" v-if="lang=='zh_ch'">行为准则</span>
							<span class="text_25"
									:style="isShowAnimation1?'position: relative;top:0;opacity: 1':''" v-else>Code of Conduct</span>
						</div>
						<div class="box_8 flex-row justify-between"
							:style="isShowAnimation1?'width: 80%;opacity: 1;':''">
							<div class="group_3 flex-col flex-align-justify" @mouseenter="conduct = index"
								v-for="(item,index) in dataMain.conduct" :key="index">
								<img class="image_9" referrerpolicy="no-referrer" :src="item.icon" />
								<span class="text_27">{{item.title}}</span>
							</div>
						</div>
					</div>

				</div>
				<div class="box_9 mainApp16 flex-row justify-between" id="zhiCi">
					<div class="box_10 flex-col">
						<span class="text_31" v-if="lang=='zh_ch'">董事长致辞：</span>
						<span class="text_31" v-else>Chairman's speech</span>
						<div class="text-wrapper_5 flex-row justify-between">
							<span class="text_32">“</span>
							<span class="paragraph_3">
								{{dataMain.speech.title}}
							</span>
						</div>
						<div class="text-group_2 flex-col justify-between">
							<span class="paragraph_4" v-if="startX>600" v-html="dataMain.speech.content"></span>
							<span class="paragraph_4" v-if="startX<=600" v-html="dataMain.speech.content"></span>
						</div>
					</div>
					<img class="image_13" referrerpolicy="no-referrer" :src="dataMain.speech.cover" v-if="startX>600" />
				</div>
				<div class="box_11 flex-col" id="history" :style="isShowAnimation2?'position: relative;':''">
					<div class="box11" :style="isShowAnimation2?'top:0;opacity: 1':'opacity: 0'">
						<div class="block_1 flex-row justify-center align-center">
							<div class="text-wrapper_6">
								<span class="text_33">d</span>
								<span class="text_34">evelopment</span>
								<span class="text_35">&nbsp;h</span>
								<span class="text_36">istory</span>
							</div>
						</div>
						<div class="text-wrapper_7 flex-row">
							<span class="text_37">发展历程</span>
						</div>
						<div class="block_2 flex-row">
							<div class="image_14"></div>
							<span class="text_38">{{dataMain.history[hisNum].year}}</span>
							<span class="paragraph_6">
								{{dataMain.history[hisNum].content}}
							</span>
						</div>
						<div class="block_3 flex-align-justify">
							<img class="image_15" referrerpolicy="no-referrer"
								src="./assets/img/ps85mefahfd86eb5yjhd446w4yhi3xrnscn897d0a48-ffb8-4963-9b41-79493bc61ee6.png" />
							<img class="image_16" referrerpolicy="no-referrer"  :style="'width:'+(startX>1920?1920:startX)*0.75/dataMain.history.length+'px'"
								src="./assets/img/ps34mg2eimmt17nu38wzav6avfdtq6b940de387d232-278f-4453-b8ae-71d47432a4a5.png" />
							<div class="flex-align-justify" style="position: relative;" @click="hisNum=index"
								v-for="(item,index) in dataMain.history" :key="index">
								<img class="image_24" referrerpolicy="no-referrer" v-if="hisNum==index"
									src="./assets/img/psomlfq1f2vvmy8sxoc6pjzqn1ujxzjkcs76215df3-1c2e-49dc-ae86-fb28f94e9c4b.png" />
								<div class="group_7 flex-col" v-else></div>
								<span class="text_39"
									:style="hisNum==index? startX>600? 'left: -14%;font-size: 48px;':'left: -13%;font-size: 4rem;    bottom: -26px;':startX>600?'':'    bottom: -26px;'">{{item.year}}</span>
								<img class="image_17" referrerpolicy="no-referrer" :style="'width:'+(startX>1920?1920:startX)*0.75/dataMain.history.length+'px'"
									src="./assets/img/pstufgy2safd66q1e4ppgvthevqjxp6un6d0890b40-694f-4d9c-b7b0-cc217366aa11.png" />
							</div>
							<img class="image_23" referrerpolicy="no-referrer"
								src="./assets/img/ps1ilop24km3vfu0jc3zzdse5lk3gwsveb43b782e0-b347-42ac-b4cf-86a03c19d05b.png" />
						</div>
					</div>
				</div>
				<div class="box_12 flex-row justify-between">
					<div class="block_4 flex-col justify-around" id="ziZhi">
						<div style="height: 2vw; max-height: 40px;"></div>
						<div class="text-group_3 flex-col justify-between">
							<div class="text-wrapper_9">
								<span class="text_45">Q</span>
								<span class="text_46">ualification</span>
								<span class="text_47">H</span>
								<span class="text_48">onor</span>
							</div>
							<span class="text_49">荣誉资质</span>
						</div>
						<div class="block_5 flex-col" :style="'background-image: url('+dataMain.honor.cover+');'"
							v-if="startX<=600">
							<div class="block_6 flex-col" >
								<div class="block_7 flex-col"></div>
								<span class="text_61">主要资质</span>
								<div class=" flex-row" style="width: 70%;flex-wrap:wrap;">
									<span class="text_62" v-for="(item,index) in dataMain.honor.im"
										:key="index">{{item.name}}</span>
								</div>

							</div>
						</div>
						<div class="box_13 flex-row justify-around">
							<div class="text-group_4 flex-col justify-between"
								v-for="(item,index) in dataMain.honor.type" :key="index">
								<span class="text_50">{{item.count}}</span>
								<span class="text_51">{{item.name}}</span>
							</div>
						</div>
						<div class="box_14 flex-row justify-around">
							<div class="text-wrapper_11 flex-col align-center" @mouseover="toType(item.type)"
								v-for="(item,index) in dataMain.honor.type" :key="index">
								<span class="text_58">{{item.name}}</span>
							</div>
						</div>
					</div>
					<div class="block_5 flex-col" v-if="startX>600">
						<img class="blockImg" :src="dataMain.honor.cover" alt="">
						<div class="block_6 flex-col" style="opacity: 0;">
							<div class="block_7 flex-col"></div>
							<span class="text_61" :style="startX>1800?'font-size:20px':'font-size:1rem;'">主要资质</span>
							<div class=" flex-row" style="width: 70%;flex-wrap: wrap;"
								:style="startX>1800?'font-size:20px':'font-size:1rem;'">
								<span class="text_62" :style="startX>1800?'line-height:60px':'line-height:4rem;'"
									v-for="(item,index) in dataMain.honor.im" :key="index">{{item.name}}</span>
							</div>

						</div>
					</div>
				</div>
				<div class="box_15 justify-center mainApp16 flex-row" id="zhengShu">
					<div class="group_10 flex-col" :class="isShow?'act':''" :id="'zhengShu'+index"
						v-for="(item,index) in honors" :key="index">
						<div class="group_11 flex-col">
							<div class="image-wrapper_3 flex-col">
								<img class="image_25" referrerpolicy="no-referrer" :src="item.picture" />
							</div>
							<div class="image_26"></div>
						</div>
						<span class="text_68"> {{item.name}}</span>
					</div>
				</div>
			</div>
			<div class="box_21  flex-col">
				<div class="box_22 flex-row">
					<div class="text-wrapper_16" id="xiaShuT">
						<span class="text_76">S</span>
						<span class="text_77">ubsidiaries</span>
					</div>
				</div>
				<div class="text-wrapper_17 flex-row">
					<span class="text_78" id="xiaShuN">下属公司</span>
				</div>
				<div class="box_23 mainApp16 flex-row justify-around">
					<div class="flex-col marTop30" style="cursor: pointer;" v-for="(item,index) in dataMain.company"
						:key="index" @mouseenter="show=index" @mouseleave="show=-1">
						<div class="box_24" v-if="show==index">
							<div class="text-wrapper_18 flex-row">
								<span class="text_79">{{item.name}}</span>
							</div>
							<div class="box_25 flex-row">
								<div class="image-text_1 flex-row justify-between">
									<img class="label_1" referrerpolicy="no-referrer"
										src="./assets/img/ps0x1wi5cu0lgimqqzgd66yd26p5x5243sj0da5372b-6b84-41d5-8f7b-37dd39a90731.png" />
									<span class="text-group_6">{{item.tel_name}}：</span>
								</div>
								<span class="text_80" v-for="(item1,index1) in item.tel" :key="index1">{{item1}}</span>
							</div>
							<div class="box_25 flex-row">
								<div class="image-text_1 flex-row justify-between">
									<img class="label_1" referrerpolicy="no-referrer"
										src="./assets/img/pscble1k5i0kqxz35xj64gxddtuqde6sepp2b309186-d497-4470-886b-e6b0687d2d27.png" />
									<span class="text-group_6">{{item.fax_name}}：</span>
								</div>
								<span class="text_80" v-for="(item1,index1) in item.fax" :key="index1">{{item1}}</span>
							</div>
						</div>
						<div class="box_27 flex-col" v-else>
							<img class="image_41" referrerpolicy="no-referrer" :src="item.logo" />
							<span class="text_83">{{item.name}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="text-wrapper_19" id="piceT">
				<span class="text_88">C</span>
				<span class="text_89">orporate</span>
				<span class="text_90">c</span>
				<span class="text_91">ulture</span>
			</div>
			<span class="text_92" id="piceN">国外销售布局图</span>
			<div class="box_30 flex-col">
				<img :src="dataMain.map" alt="">
			</div>
		</div>
		<div class="" v-if="startX>600">
			<body-footer></body-footer>
		</div>
		<div class="" v-else>
			<body-footer1></body-footer1>
		</div>
	</div>

</template>
<script>
	import headTop from "../../components/headTop.vue";
	import bodyFooter from "../../components/bodyFooter.vue";
	import bodyFooter1 from "../../components/bodyFooter1.vue";
	import {
		about,
		honorList
	} from "../../util/api";
	export default {
		data() {
			return {
				constants: {},
				show: 0,
				list: ['天津市臣涛精密机械有限公司', '天津市金涞精密机械有限公司', '徐州金涞精密机械有限公司',
					'天津宝涞工业机器人应用技术研究有限公司', '宝涞美国有限公司', '天津市臣涛精密机械有限公司'
				],
				startX: 1200,
				dataMain: {
					banner: {},
					about: {},
					culture: [],
					speech: {
						title: '',
						content: '',
						cover: ''
					},
					history: [],
					honor: {},
					company: [],
					map: ''
				},
				hisNum: 0,
				honors: [],
				hon: 1,
				conduct: -1,
				isShowAnimation: false, //企业文化交互效果
				isShowAnimation1: false, //行为准则交互效果
				isShowAnimation2: false, //发展历程交互效果
				isShow: false, //荣誉资质交互效果
			};
		},

		components: {
			headTop,
			bodyFooter,
			bodyFooter1,
		},
		created() {
			this.getDate()

		},
		mounted() {
			this.startX = document.body.clientWidth;
			window.onresize = () => {
				return (() => {
					this.startX = document.body.clientWidth;
				})();
			};
			window.addEventListener("scroll", this.scrollHandle, true); // 监听 监听元素是否进入/移出可视区域
			window.addEventListener("scroll", this.scrollHand, true); // 监听 监听元素是否进入/移出可视区域
			window.addEventListener("scroll", this.scrollHandle1, true);
			window.addEventListener("scroll", this.scrollHandle2, true);
			// console.log(this.startX);
		},
		deforeDestroy() {
			window.removeEventListener('scroll', this.scrollHandle, true);
			window.removeEventListener('scroll', this.scrollHand, true);
			window.removeEventListener('scroll', this.scrollHandle1, true);
			window.removeEventListener('scroll', this.scrollHandle2, true);
		},
		computed: {
			lang() {
				return this.$store.getters.getLang;
			},
		},
		watch: {
			startX(val, oldval) {
				if (val != oldval) {
					// console.log(this.startX, "屏幕宽度");
				}
			},
			lang(val, oldval) {
				if (val != oldval) {
					this.getDate()
					// console.log(this.startX, this.widNum, "屏幕宽度");
				}
			},
		},
		methods: {
			getDate() {
				about({
					lang: this.lang
				}).then((res) => {
					console.log(res);
					this.dataMain = res.data
					this.getList()
					// this.title = res.data.header_nav;
				});
			},
			getList() {
				honorList({
					lang: this.lang,
					type: this.hon
				}).then((res) => {
					// console.log(res);
					this.honors = res.data.list
					// this.title = res.data.header_nav;
				});
			},
			toIndex(v) {
				if (v.target_rule == 1) {
					this.$router.push("/");
				} else if (v.target_rule == 2) {
					this.$router.push("/aboutUs");
				} else if (v.target_rule == 3) {
					let id = v.param
					this.$router.push("/news/" + id);
				} else if (v.target_rule == 4) {
					let news_id = v.param
					this.$router.push("/newDetail/" + news_id);
				} else if (v.target_rule == 5) {
					let id = v.param
					console.log(id);
					this.$router.push("/product/" + id);
				} else if (v.target_rule == 6) {
					this.$router.push("/companies");
				} else if (v.target_rule == 7) {
					this.$router.push("/Investor");
				} else if (v.target_rule == 8) {
					this.$router.push("/invite");
				} else if (v.target_rule == 9) {
					this.$router.push("/contact");
				}
			},
			toType(type) {
				this.hon = type
				this.getList()
			},
			scrollHand() {
				if (this.startX > 600) {
					let about = this.isInViewPortOfOne(document.getElementById('about'))
					if (about) {
						document.getElementById('about').style.marginLeft = '75px'
						document.getElementById('about').style.opacity = '1'
					} else {
						document.getElementById('about').style.marginLeft = '275px'
						document.getElementById('about').style.opacity = '0'
					}
					let zhiCi = this.isInViewPortOfOne(document.getElementById('zhiCi'))
					if (zhiCi) {
						document.getElementById('zhiCi').style.transform = 'scale(1)'
						document.getElementById('zhiCi').style.opacity = '1'
					} else {
						document.getElementById('zhiCi').style.transform = 'scale(0.7)'
						document.getElementById('zhiCi').style.opacity = '0'
					}
					let ziZhi = this.isInViewPortOfOne(document.getElementById('ziZhi'))
					if (ziZhi) {
						document.getElementById('ziZhi').style.transform = 'scale(1)'
						document.getElementById('ziZhi').style.opacity = '1'
					} else {
						document.getElementById('ziZhi').style.transform = 'scale(0.7)'
						document.getElementById('ziZhi').style.opacity = '0'
					}
					let zhengShu = this.isInViewPortOfOne(document.getElementById('zhengShu'))
					for (let i = 0; i < this.honors.length; i++) {
						let id = 'zhengShu' + i
						if (zhengShu) {
							setTimeout(() => {
								document.getElementById(id).style.opacity = '1'
							}, i * 300 + 300)
						} else {
							document.getElementById(id).style.opacity = '0'
						}
					}
					let xiaShuT = this.isInViewPortOfOne(document.getElementById('xiaShuT'))
					if (xiaShuT) {
						document.getElementById('xiaShuT').style.fontSize = '72px'
						document.getElementById('xiaShuT').style.opacity = '1'
						document.getElementById('xiaShuN').style.fontSize = '40px'
						document.getElementById('xiaShuN').style.opacity = '1'
					} else {
						document.getElementById('xiaShuT').style.fontSize = '2px'
						document.getElementById('xiaShuT').style.opacity = '0'
						document.getElementById('xiaShuN').style.fontSize = '2px'
						document.getElementById('xiaShuN').style.opacity = '0'
					}
					let piceT = this.isInViewPortOfOne(document.getElementById('piceT'))
					if (piceT) {
						document.getElementById('piceT').style.fontSize = '72px'
						document.getElementById('piceT').style.opacity = '1'
						document.getElementById('piceN').style.fontSize = '40px'
						document.getElementById('piceN').style.opacity = '1'
					} else {
						document.getElementById('piceT').style.fontSize = '2px'
						document.getElementById('piceT').style.opacity = '0'
						document.getElementById('piceN').style.fontSize = '2px'
						document.getElementById('piceN').style.opacity = '0'
					}
				}
			},
			isInViewPortOfOne(el) {
				// viewPortHeight 兼容所有浏览器写法
				const offset = el.getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					return true
				} else {
					return false
					// console.log('移出可视区域');
				}
			},
			scrollHandle() {
				const offset = document.getElementById('culture').getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				// const offsetHeight = offset.height;
				// 进入可视区域
				// console.log(offsetTop,offsetBottom)

				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');\
					if (this.startX > 600) {
						this.isShowAnimation = true
					}
				} else {
					this.isShowAnimation = false
					// console.log('移出可视区域');

				}

			},
			scrollHandle1() {
				const offset = document.getElementById('action').getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				// const offsetHeight = offset.height;
				// 进入可视区域
				// console.log(offsetTop,offsetBottom)
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					if (this.startX > 600) {
						this.isShowAnimation1 = true
					}
				} else {
					this.isShowAnimation1 = false
					// console.log('移出可视区域');

				}
			},
			scrollHandle2() {
				const offset = document.getElementById('history').getBoundingClientRect();
				const offsetTop = offset.top;
				const offsetBottom = offset.bottom;
				// const offsetHeight = offset.height;
				// 进入可视区域
				// console.log(offsetTop,offsetBottom)
				if (offsetTop <= window.innerHeight && offsetBottom >= 0) {
					// console.log('进入可视区域');
					// if (this.startX > 600) {
						this.isShowAnimation2 = true
					// }
				} else {
					this.isShowAnimation2 = false
					// console.log('移出可视区域');

				}
			}
		},
	};
</script>
<style scoped lang="css" src="./assets/index.css" />
<style lang="scss" scoped>
	.wid600 {
		width: 100%;

		.section_3 {
			.box_2 {
				margin-top: 15.4rem;
				flex-wrap: wrap;

				.image_3 {
					width: 54rem;
					height: 31rem;
					float: left;
					margin-top: -21rem;
					margin-right: 5rem;
				}

				.section_4 {
					margin-top: 3.4rem;
					margin-left: 47vw;
					// margin-right: 6rem;

					.text-wrapper_1 {
						font-size: 8.5rem;
						line-height: 8.5rem;
						text-transform: uppercase;
						// padding-left: 12px;
					}

					.text_14 {
						font-size: 5rem;
						line-height: 5rem;
						// padding-left: 12px;
						margin-top: 4rem;
					}
				}

				.paragraph_1 {
					margin-top: 3rem;
					width: 113rem;
					font-size: 3.7rem;
					line-height: 8rem;
					// margin-left: 3.4rem;
					// display: flex;
					// flex-wrap: wrap;

					.image50 {
						width: 44rem;
						height: 24rem;
						margin-left: 13rem;
						margin-right: 2.7rem;
						float: left;
						margin-top: -6rem;
					}

					.paragraph-text {
						width: 47%;
					}

				}
			}

			.text-wrapper_2 {
				font-size: 6.667rem;
				line-height: 5.333rem;
				margin-top: 13.333rem;
				width: 100%;
				margin-right: 0;
				padding-left: 6.667rem;
				text-transform: uppercase;
				font-family: Acumin Variable Concept;
				font-weight: normal;
			}

			.text_19 {
				font-size: 5rem;
				line-height: 7.333rem;
				margin-top: 4rem;
				width: 100%;
				margin-right: 0;
				padding-left: 6.667rem;
			}

			#culture {
				width: 100% !important;
			}

			.box_3 {
				margin-top: 10rem;
				width: 100%;
				height: auto;

				.image_4 {
					width: 59rem;
					height: 28rem;
					margin-left: -4rem;
				}

				.box_4 {
					margin-top: 3.333rem;
					height: auto;
					width: auto;
					margin-left: 0px;

					.image_5 {
						width: 4rem;
						height: 1rem;
						border-radius: .5rem;
						margin-left: 3.4rem;
					}

					.image_6 {
						width: 4rem;
						height: 1rem;
						border-radius: 0.5rem;
						margin-left: 4rem;
					}

					.text_20 {
						font-size: 4.333rem;
						line-height: 4.33rem;
						height: auto;
						margin-top: 4rem;
						margin-left: 3.4rem;
					}

					.text_21 {
						height: auto;
						width: auto;
						font-size: 3rem;
						line-height: 4rem;
						margin-top: 2.5rem;
						margin-left: 3.4rem;
					}

					.text_22 {
						height: auto;
						font-size: 3.3rem;
						line-height: 7.333rem;
						width: 50rem;
						white-space: pre-wrap;
						margin: 4rem 0 0 3.4rem;
					}
				}
			}

			.box_5 {
				width: 100%;
				margin: 0 auto;
				position: relative;
				height: auto;
				padding-left: 0;

				.box_6 {
					width: 100%;
					margin-top: 7.333rem;
					padding-left: 3rem;
					height: auto;

					.image_6 {
						width: 4rem;
						height: 1rem;
						border-radius: .5rem;
					}

					.text-group_1 {
						margin-top: 4rem;
						height: auto;

						.text_23 {
							font-size: 4.333rem;
							line-height: 4.333rem;
							height: auto;
						}

						.text_24 {
							font-size: 3rem;
							line-height: 3.667rem;
							height: auto;
							margin-top: 2.333rem;
						}
					}

					.paragraph_2 {
						font-size: 3.333rem;
						font-family: Noto Sans S Chinese;
						font-weight: 400;
						color: #333333;
						line-height: 7.333rem;
						margin-top: 6rem;
						height: auto;
						width: 47%;
					}

					.paragraph_3 {
						height: auto;

						font-size: 3.333rem;
						font-family: Noto Sans S Chinese;
						font-weight: 400;
						color: #333333;
						line-height: 7.333rem;
					}
				}

				.image_7 {
					width: 60rem;
					height: 28rem;
					position: absolute;
					right: 0;
					top: 7.333rem;
				}
			}

			.box_7 {
				margin-top: 11.333rem;
				height: auto;

				.box7 {
					width: 100%;

					.image-wrapper_2 {
						margin: 8.333rem 0 0 8.333rem;
						height: auto;

						.image_8 {
							width: 3.667rem;
							height: 1rem;
							border-radius: .5rem;
							position: relative;
							top: 0;
						}
					}

					.text-wrapper_3 {
						height: auto;
						margin: 4rem 0 0 8.333rem;

						.text_25 {
							font-size: 4.333rem;
							line-height: 4.333rem;
							position: relative;
							top: 0;
						}
					}

					.text-wrapper_4 {
						height: auto;
						margin: 2.333rem 0 0 8.333rem;

						.text_26 {
							font-size: 3rem;
							line-height: 3.667rem;
						}
					}

					.box_8 {
						width: 80%;
						height: auto;
						margin: 0 auto;
						padding: 5rem 0 13.333rem 0;
						flex-wrap: wrap;

						.group_3 {
							width: 43.333rem;
							height: 36.667rem;
							border-radius: 3.333rem;
							margin-top: 5rem;
							min-width: 0;

							.image_9 {
								width: 14.333rem;
								height: 16.333rem;
							}

							.text_27 {
								margin-top: 5rem;
								font-size: 4rem;
								line-height: 4rem;
							}
						}
					}
				}
			}

			.box_9 {
				width: 100%;
				height: auto;
				margin: 13.333rem 0 0 7.667rem;
				position: relative;
				transform: scale(1);

				.box_10 {
					width: 95%;
					height: auto;

					.text_31 {
						font-size: 5rem;
						line-height: 7.333rem;
						height: auto;
					}

					.text-wrapper_5 {
						margin-top: 6.667rem;
						height: auto;
						width: 100%;

						.text_32 {
							font-size: 12rem;
							height: auto;
							// margin-left: -6.667rem;
							font-family: Arial;
						}

						.paragraph_3 {
							width: 97.333rem;
							height: auto;
							font-size: 3.667rem;
							line-height: 7.333rem;
							margin-right: 10rem;
						}
					}

					.text-group_2 {
						margin-top: 6.667rem;
						width: 96%;
						height: auto;

						.paragraph_4 {
							width: 100%;
							height: auto;
							font-size: 3.667rem;
							line-height: 7.333rem;
						}

						.paragraph_5 {
							margin: 0;
							margin-top: 3rem;
							width: 51.3rem;
							height: auto;
							font-size: 3.667rem;
							line-height: 7.333rem;
						}
					}

				}

				.image_13 {
					width: 60.667rem;
					height: 45.667rem;
					position: absolute;
					right: 3%;
					top: 69.667rem;
				}
			}

			.box_11 {
				width: 100%;
				margin-top: 13.333rem;
				height: auto;
				padding-bottom: 15rem;

				.box11 {
					position: relative;
					top: 0px;
				}

				.block_1 {
					width: 100%;
					height: auto;
					margin: 0 auto;
					margin-top: 13rem;

					.text-wrapper_6 {
						width: 100%;
						height: auto;
						text-transform: uppercase;
						font-size: 6.667rem;
						line-height: 5.333rem;
					}

				}

				.text-wrapper_7 {
					margin: 0 auto;
					height: auto;
					margin-top: 4rem;

					.text_37 {
						font-size: 5rem;
						height: auto;
						line-height: 5rem;
					}
				}

				.block_2 {
					margin: 0 auto;
					height: auto;
					margin-top: 8.667rem;
					align-items: center;

					.image_14 {
						margin-left: 5rem;
						width: 3.667rem;
						height: 1rem;
						border-radius: .5rem;
						margin-top: 0;
						margin-bottom: 17rem;
					}

					.text_38 {
						margin: 0;
						// margin-top: 13.333rem;
						margin-bottom: 17rem;
						margin-left: 3rem;
						width: auto;
						height: auto;
						font-size: 5.333rem;
						line-height: 5.333rem;
					}

					.paragraph_6 {
						width: 86rem;
						height: auto;
						font-size: 3rem;
						line-height: 7.333rem;
						margin-left: 6rem;
					}
				}

				.block_3 {
					width: 100%;
					height: auto;
					margin: 0 auto;
					display: flex;
					align-items: center;
					justify-content: center;

					.image_15 {
						width: 1.333rem;
						height: 1.667rem;
					}

					.image_16 {
						width: 15.333rem;
						height: 0.333rem;
						background: #FFFFFF;
						border-radius: 0.12rem;
						margin: 0;
					}

					.text_39 {
						width: auto;
						height: auto;
						font-size: 2rem;
						line-height: 3.7rem;
					}

					.image_24 {
						position: inherit;
						width: 4rem;
						height: 6rem;
					}

					.image_17 {
						width: 15.333rem;
						height: .5rem;
						margin: 0;
						// margin-left: 3rem;
					}

					.image_18 {
						width: 16.333rem;
						height: 1rem;
						margin: 0;
					}

					.group_7 {
						width: 1rem;
						height: 1rem;
						margin: 0;
						border-radius: 50%;
					}

					.image_22 {
						width: 15.333rem;
						height: 0.333rem;
						border-radius: 0.12rem;
						margin: 0;
					}

					.image_23 {
						width: 1.333rem;
						height: 1.667rem;
					}
				}

				.text-wrapper_8 {
					width: 85%;
					height: auto;
					margin: 0 auto;
					margin: 1.5rem auto 7rem auto;
					justify-content: space-around;



					.text_40 {
						width: auto;
						height: auto;
						margin: 0;
						font-size: 2.333rem;
						line-height: 3rem;
					}
				}
			}

			.box_12 {
				width: 100%;
				height: auto;
				margin: 0 auto;

				.block_4 {
					width: 100%;
					height: auto;
					margin-top: 13.333rem;
					padding-left: 5.667rem;

					.text-group_3 {
						width: auto;
						height: auto;

						.text-wrapper_9 {
							text-transform: uppercase;
							font-size: 6.667rem;
							line-height: 5.333rem;
						}

						.text_49 {
							margin-top: 3.333rem;
							font-size: 5rem;
							line-height: 7.333rem;
						}
					}

					.block_5 {
						width: 110.667rem;
						height: 62rem;
						margin-top: 8rem;
						margin-left: 4.667rem;

						.block_6 {
							width: 85.667rem;
							height: 45.333rem;
							margin-top: 17rem;
							opacity: 1;

							.block_7 {
								width: 100%;
								height: 2rem;
							}

							.text_61 {
								margin: 5.333rem 0 0 7.333rem;
								font-size: 3.333rem;
								line-height: 3.333rem;
								width: auto;
								height: auto;
							}

							.text_62 {
								margin: 4rem 0 0 7.333rem;
								font-size: 2.667rem;
								line-height: 2.667rem;
								width: auto;
								height: auto;
							}

							.text_63 {
								margin: 3.3rem 0 0 7.333rem;
								font-size: 2.667rem;
								line-height: 2.667rem;
								width: auto;
								height: auto;
							}

							.text_64 {
								margin: 3.3rem 0 0 7.333rem;
								font-size: 2.667rem;
								line-height: 2.667rem;
								width: auto;
								height: auto;
							}

							.text-wrapper_14 {
								width: 75%;
								height: auto;
								margin: 3.3rem 0 0 7.333rem;

								.text_65 {
									width: auto;
									height: auto;
									font-size: 2.667rem;
									line-height: 2.667rem;
								}

								.text_66 {
									width: auto;
									height: auto;
									font-size: 2.667rem;
									line-height: 2.667rem;
								}
							}

							.text-wrapper_15 {
								width: 75%;
								height: auto;
								margin: 3.3rem 0 0 7.333rem;

								.text_67 {
									width: auto;
									height: auto;
									font-size: 2.667rem;
									line-height: 2.667rem;
								}

								.paragraph_7 {
									width: auto;
									height: auto;
									font-size: 2.667rem;
									line-height: 2.667rem;
								}
							}
						}
					}

					.box_13 {
						width: 100%;
						height: auto;
						margin: 0 auto;
						justify-content: space-around;
						padding-top: 10rem;

						.text-group_4 {
							width: auto;
							height: auto;
							display: flex;
							align-items: center;
							justify-content: center;

							.text_50 {
								width: auto;
								height: auto;
								font-size: 8rem;
								line-height: 7.333rem;
								margin: 0;
							}

							.text_51 {
								width: auto;
								height: auto;
								font-size: 3.333rem;
								line-height: 7.333rem;
								margin: 0;
							}
						}

						.group_8 {
							width: auto;
							height: auto;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 0;

							>div {
								.text_52 {
									width: auto;
									height: auto;
									font-size: 8rem;
									line-height: 7.333rem;
									margin: 0;
								}

								.text_54 {
									width: auto;
									display: flex;
									justify-content: flex-start;
									align-items: center;
									// height: auto;
									font-size: 5rem;
									line-height: 7.333rem;
									margin: 0;
								}
							}

							.text_53 {
								width: auto;
								height: auto;
								font-size: 3.333rem;
								line-height: 7.333rem;
								margin: 0;
							}
						}

						.group_9 {
							width: auto;
							height: auto;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-left: 0;

							.text-wrapper_10 {
								width: auto;
								height: auto;
								margin-left: 0;

								.text_55 {
									width: auto;
									height: auto;
									font-size: 8rem;
									line-height: 7.333rem;
									margin: 0;
								}

								.text_56 {
									width: auto;
									display: flex;
									justify-content: flex-start;
									align-items: center;
									// height: auto;
									font-size: 5rem;
									line-height: 7.333rem;
									margin: 0;
								}
							}

							.text_57 {
								width: auto;
								height: auto;
								font-size: 3.333rem;
								line-height: 7.333rem;
								margin: 0;
							}
						}
					}

					.box_14 {
						width: 99%;
						height: auto;
						margin: 0;
						margin-left: -2rem;
						margin-top: 11.667rem;

						.text-wrapper_11 {
							width: 30rem;
							height: 8rem;
							border-radius: 1rem;

							.text_58 {
								font-size: 3rem;
								line-height: 7rem;
							}
						}

						.text-wrapper_11 {
							width: 30rem;
							height: 8rem;
							border-radius: 1rem;
							margin-left: 5rem;

							.text_58 {
								font-size: 3rem;
								line-height: 7rem;
							}
						}

						.text-wrapper_12 {
							width: 30rem;
							height: 8rem;
							border-radius: 1rem;
							margin-left: 5rem;

							.text_59 {
								font-size: 3rem;
								line-height: 7rem;
							}
						}

						.text-wrapper_13 {
							width: 30rem;
							height: 8rem;
							border-radius: 1rem;

							.text_60 {
								font-size: 3rem;
								line-height: 7rem;
							}
						}
					}
				}
			}

			.box_15 {
				width: 100%;
				height: auto;
				display: grid;
				grid-template-columns: auto auto;
				padding: 0 4rem;
				min-width: 300px;

				.group_10 {
					width: 54rem;
					height: 61.333rem;
					margin-bottom: 5rem;
					margin-right: 2rem;

					.group_11 {
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;

						.image-wrapper_3 {
							width: 100%;
							height: 36.667rem;
							margin: 0;
							display: flex;
							justify-content: center;
							align-items: center;

							.image_25 {
								width: 36.667rem;
								height: 36.667rem;
								margin: 0;
							}
						}

						.image_26 {
							width: 47rem;
							height: 0.333rem;
							border-radius: 0.12rem;
							margin: 0;
							margin-top: 4.667rem;
						}


					}

					.text_68 {
						margin: 0 auto;
						margin-top: -9rem;
						font-size: 5rem;
						z-index: 9;
					}
				}
			}

			.box15 {
				margin-top: 0;
			}



		}

		.box_21 {
			margin-top: 7rem;
			height: auto;

			.box_22 {
				margin-top: 10rem;
				height: auto;

				.text-wrapper_16 {
					height: auto;
					text-transform: uppercase;
					font-size: 6.667rem;
					line-height: 5.333rem;
				}
			}

			.text-wrapper_17 {
				margin-top: 4rem;
				height: auto;

				.text_78 {
					font-size: 5rem;
					height: auto;
					line-height: 7.333rem;
				}
			}

			.box_23 {
				margin-top: 1rem;
				padding-bottom: 10rem;
				width: 97%;
				min-width: 300px;

				.marTop30 {
					margin-top: 5rem;

					.box_27 {
						width: 56.667rem;
						height: 24rem;

						.image_41 {
							width: 17rem;
							height: 5rem;
						}

						.text_83 {
							font-size: 2.4rem;
							margin-top: 2rem;
							white-space: pre-line;
						}
					}

					.box_24 {
						width: 56.667rem;
						height: 24rem;

						.text-wrapper_18 {
							width: auto;
							height: auto;
							margin: 2.667rem 0 0 3.2rem;

							.text_79 {
								font-size: 2.4rem;
								width: auto;
								height: auto;
							}
						}

						.box_25 {
							width: 80%;
							height: auto;
							margin: 2.667rem 0 0 3.2rem;

							.image-text_1 {
								width: 15rem;
								height: 2.333rem;

								.label_1 {
									width: 2.333rem;
									height: 2.6rem;
								}

								.text-group_6 {
									width: auto;
									height: auto;
									font-size: 2.2rem;
									line-height: 2.2rem;
									margin-top: 0.3rem;
								}
							}

							.text_80 {
								width: auto;
								height: auto;
								font-size: 2.2rem;
								line-height: 2.2rem;
								margin-left: 2rem;
								margin-top: 0.4rem;
							}

							.text_81 {
								width: auto;
								height: auto;
								font-size: 2.2rem;
								line-height: 2.2rem;
								margin-left: 2rem;
								margin-top: 0.4rem;
							}
						}

						.box_26 {
							width: 40%;
							height: auto;
							margin: 2.667rem 0 0 3.2rem;

							.image-text_2 {
								width: 11rem;
								height: 2.333rem;

								.label_2 {
									width: 2.333rem;
									height: 2.6rem;
								}

								.text-group_7 {
									width: auto;
									height: auto;
									font-size: 2.2rem;
									line-height: 2.2rem;
									margin-top: 0.3rem;
								}
							}

							.text_82 {
								width: auto;
								height: auto;
								font-size: 2.2rem;
								line-height: 2.2rem;
								margin-left: 2rem;
								margin-top: 0.4rem;
							}

						}
					}
				}
			}
		}

		.text-wrapper_19 {
			height: auto;
			margin-top: 13.333rem;
			font-size: 8.667rem;
			line-height: 8.667rem;
		}

		.text_92 {
			height: auto;
			margin-top: 4rem;
			font-size: 5rem;
			line-height: 5rem;
		}

		.box_30 {
			width: 121.667rem;
			height: 60.667rem;
			margin-top: 10rem;
			margin-bottom: 10rem;
			min-width: 0;

			.block_8 {
				width: 7rem;
				height: auto;
				margin: 10.667rem 0 0 59.333rem;

				.section_13 {
					width: 1.67rem;
					height: 1.67rem;
					display: flex;
					align-items: center;
					justify-content: center;

					.block_9 {
						width: 0.8rem;
						height: 0.8rem;
						margin: 0 auto;
						// margin-top: 0.4rem;
					}
				}

				.text_93 {
					width: auto;
					height: auto;
					font-size: 2.333rem;
					line-height: 2.333rem;
				}
			}

			.block_10 {
				width: 7rem;
				height: auto;
				margin: 1rem 0 0 52rem;

				.box_31 {
					width: 1.67rem;
					height: 1.67rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 3rem;

					.block_11 {
						width: 0.8rem;
						height: 0.8rem;
						margin: 0 auto;
						// margin-top: 0.4rem;
					}
				}

				.box_32 {
					width: 1.67rem;
					height: 1.67rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 2rem;
					margin-top: .6rem;

					.box_33 {
						width: 0.8rem;
						height: 0.8rem;
						margin: 0 auto;
						// margin-top: 0.4rem;
					}
				}

				.box_34 {
					width: auto;
					height: auto;
					margin-left: 0rem;

					.text_94 {
						font-size: 2.333rem;
						line-height: 2.333rem;
					}

					.group_17 {
						width: auto;
						height: auto;
						margin: 0;

						.section_14 {
							width: 1.67rem;
							height: 1.67rem;
							display: flex;
							align-items: center;
							justify-content: center;

							.section_15 {
								width: 0.8rem;
								height: 0.8rem;
								margin: 0 auto;
								// margin-top: 0.4rem;
							}
						}

						.text_95 {
							font-size: 2.333rem;
							line-height: 2.333rem;
						}
					}

				}
			}

			.block_12 {
				margin: 0.3rem 0 0 11rem;

				.group_18 {
					width: 1.67rem;
					height: 1.67rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: -6.5rem;

					.box_35 {
						width: 0.8rem;
						height: 0.8rem;
						margin: 0 auto;
						// margin-top: 0.4rem;
					}
				}

				.text_96 {
					width: auto;
					height: auto;
					font-size: 2.333rem;
					line-height: 2.333rem;
					margin: 0;
					margin-top: -7rem;
				}

				.text_97 {
					width: auto;
					height: auto;
					font-size: 2.333rem;
					line-height: 2.333rem;
					margin-left: 32rem;
					margin-top: -6rem;
				}

				.group_19 {
					width: 1.67rem;
					height: 1.67rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: -7rem 0 0 47rem;

					.block_13 {
						width: 0.8rem;
						height: 0.8rem;
						margin: 0 auto;
						// margin-top: 0.4rem;
					}
				}

				.group_20 {
					width: 1.67rem;
					height: 1.67rem;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: -7rem 0 0 3rem;

					.section_16 {
						width: 0.8rem;
						height: 0.8rem;
						margin: 0 auto;
						// margin-top: 0.4rem;
					}
				}

				.text_98 {
					width: auto;
					height: auto;
					font-size: 2.333rem;
					line-height: 2.333rem;
					margin: -7.5rem 0 0 0;
				}
			}

			.text-wrapper_20 {
				width: auto;
				height: auto;
				margin: -5rem 0 0px 100.667rem;

				.text_99 {
					width: auto;
					height: auto;
					font-size: 2.333rem;
					line-height: 2.333rem;
				}
			}
		}
	}
</style>